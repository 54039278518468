import { Border, Spacing } from 'atomic/legacy/obj.constants'
import styled, { css, keyframes } from 'styled-components'

const shimmerAnimation = keyframes`
  0%{
    background-position: -1000px 0
  }
  100%{
    background-position: 1000px 0
  }
`

export interface ShimmerProps {
  width?: string
  height?: string
  margin?: string
}

export const BaseShimmerBoxStyled = styled.div`
  animation-fill-mode: forwards;
  animation: ${shimmerAnimation} 3s linear infinite;
  background: linear-gradient(90deg, #f5f5f5 8%, #eaeaea 38%, #f5f5f5 60%);
  background-size: 1000px;
  position: relative;
  border-radius: ${Border.Radius};
`

export const textShimmerType = (width: string, height?: string, margin?: string) => css`
  height: ${height};
  width: ${width};
  margin: ${margin || `${Spacing.Small} 0`};
`

export const TextShimmerBoxStyled = styled(BaseShimmerBoxStyled)`
  ${(props: ShimmerProps) => textShimmerType(props.width ? props.width : '100%', props.height, props.margin)};
`

export const thumbShimmerType = (x: string, y?: string, margin?: string) => css`
  width: ${x};
  height: ${y ? y : x};
  margin: ${margin || 'auto'};
`

export const ThumbShimmerBoxStyled = styled(BaseShimmerBoxStyled)`
  ${(props: ShimmerProps) => thumbShimmerType(props.width ? props.width : '100%', props.height, props.margin)};
`

export interface ShimmerAspectRatioBoxProps {
  ratioPercentage: number
}

export const ShimmerAspectRatioBoxStyled = styled(BaseShimmerBoxStyled)`
  width: 100%;
  padding-bottom: ${(props: ShimmerAspectRatioBoxProps) => props.ratioPercentage}%;
`

export interface ShimmerCircleProps {
  /** in pixels */
  radius?: number
}

export const ShimmerCircleStyled = styled(BaseShimmerBoxStyled)`
  height: ${(props: ShimmerCircleProps) => 2 * props.radius}px;
  width: ${(props: ShimmerCircleProps) => 2 * props.radius}px;
  border-radius: ${(props: ShimmerCircleProps) => props.radius}px;
`

import React from 'react'

const SvgBuscaComErro = props => (
  <svg viewBox="0 0 379 197" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M66 135h246V16a4 4 0 00-4-4H70a4 4 0 00-4 4v119z" fill="#EFEFEF" />
      <g transform="translate(161 43)" stroke="#77787B">
        <circle strokeWidth={3} cx={30} cy={30} r={30} />
        <path
          d="M30.5 30l8.485-8.485L30.5 30l-8.485-8.485L30.5 30zm0 0l8.485 8.485L30.5 30l-8.485 8.485L30.5 30z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3.5}
        />
      </g>
      <path
        d="M189.5 157c.405 0 .78-.158 1.058-.435a1.493 1.493 0 00.412-1.358 1.184 1.184 0 00-.083-.278c-.037-.09-.09-.18-.142-.263a1.092 1.092 0 00-.188-.226 1.497 1.497 0 00-1.35-.412 1.722 1.722 0 00-.54.224c-.082.054-.157.122-.225.188a1.092 1.092 0 00-.187.226 1.779 1.779 0 00-.142.263c-.038.09-.068.18-.083.278a1.493 1.493 0 00.412 1.358c.278.277.653.435 1.058.435"
        fill="#77787B"
      />
      <path
        d="M158.347 193.616c7.83-5.968 12.498-15.239 12.498-25.358v-1.907h36.3v1.907c0 10.12 4.676 19.39 12.506 25.358h-61.304zM57.125 154.21v-5.865h263.75v5.865c0 4.828-3.932 8.756-8.752 8.756H65.876c-4.825 0-8.751-3.928-8.751-8.756zM65.876 3.383h246.247c4.82 0 8.752 3.929 8.752 8.757v132.82H57.125V12.14c0-4.828 3.926-8.757 8.751-8.757zM270.76 182.051v2.84c0 .933.752 1.691 1.69 1.691.93 0 1.692-.758 1.692-1.692v-2.839c6.891.685 12.379 5.493 13.258 11.565H257.5c.87-6.072 6.367-10.88 13.259-11.565zm20.112 11.565c-.947-8.434-8.828-15.038-18.374-15.038-9.555 0-17.436 6.604-18.374 15.038h-28.147c-9.53-4.923-15.449-14.6-15.449-25.358v-1.907h101.596c6.688 0 12.134-5.447 12.134-12.141V12.14c0-6.695-5.446-12.14-12.134-12.14H65.876c-6.69 0-12.134 5.445-12.134 12.14v142.07c0 6.694 5.443 12.14 12.134 12.14h101.586v1.908c0 10.757-5.91 20.435-15.44 25.358H1.742c-.933 0-1.742.758-1.742 1.692C0 196.243.809 197 1.742 197H377.31c.93 0 1.691-.757 1.691-1.692 0-.934-.761-1.692-1.691-1.692H290.87z"
        fill="#77787B"
      />
      <g transform="rotate(-45 266.619 -304.532)">
        <g stroke="#77787B" strokeWidth={3}>
          <path d="M20 43.109h4.5V74.5a3 3 0 01-6 0V43.109H20z" strokeLinejoin="round" />
          <circle fill="#FFF" cx={21.5} cy={21.5} r={21.5} />
        </g>
        <circle fill="#EFEFEF" cx={21.5} cy={21.5} r={15.5} />
      </g>
    </g>
  </svg>
)

export default SvgBuscaComErro

export const getSearchJsonLd = (data: {
  url: string
  searchPath: string
  searchQueryParamName: string
}) => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: data.url,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${data.url}/${data.searchPath}?${data.searchQueryParamName}={search_term_string}`,
    'query-input': 'required name=search_term_string'
  }
})

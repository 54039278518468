import sanitizeRegex from '@root/src/utils/sanitize-regex-elastic'
import { FetchDataSourceInput } from '../../fetch.datasource'
// import { getStorage, StorageType } from '../../storage/storage.provider'

// import { ElasticSearchIndex } from '../utils/elastic-search-index'

export const buildGlobalElasticSearchRequest = (
  search: string,
  // indexList: ElasticSearchIndex[],
  page: number,
  limit: number,
  selectedIndex: string
) => {
  const request: FetchDataSourceInput = {
    method: 'post',
    url: `${process.env.GATSBY_FLEURY_BASE_URL}/elasticsearch/digital/free-search`,
    data: buildGlobalElasticSearchRequestData(search, page, limit, selectedIndex)
  }
  return request
}

/**
 * You can use this to search in any index
 */
// const getGlobalElasticSearchURL = (indexList: ElasticSearchIndex[]) => ``
// `/${indexList.join(',')}/_search`

/**
 * `search as you type`: https://www.elastic.co/guide/en/elasticsearch/reference/7.x/search-as-you-type.html
 * `highlight`: https://www.elastic.co/guide/en/elasticsearch/reference/6.7/search-request-highlighting.html
 */
const buildGlobalElasticSearchRequestData = (
  search: string,
  page: number,
  limit: number,
  selectedIndex: string
) => {
  return {
    search: {
      _source: ['title', 'slug', 'thumbnail', 'type_slug', 'name'],
      query: {
        multi_match: {
          query: `${search?.replace(sanitizeRegex, '')}`,
          type: 'phrase_prefix'
        }
      },
      highlight: {
        fields: {
          '*': {}
        },
        pre_tags: ['<strong>'],
        post_tags: ['</strong>'],
        boundary_scanner_locale: 'pt-BR'
      },
      from: (page - 1) * limit,
      size: limit,
      sort: '_score'
    },
    index: `${process.env.GATSBY_COSMIC_BUCKET.toLocaleLowerCase()}${
      selectedIndex ? '-' + selectedIndex : '*'
    }`
    // index: `${process.env.GATSBY_MARCA.toLocaleLowerCase()}*`
  }
}

import { Col, Grid, Row } from 'atomic'
import { SearchQueryParam } from '@app/components/legacy/mol.search/debounced-search.utils'
import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from '@app/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { GlobalSearchContext } from '@app/context/global-search'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { Query } from '@root/src/data/graphql/graphql-types'
import { PageProps } from '@app/utils/local-types'
import { appPaths, getCanonicalUrl, pagesPaths } from 'utils/path'
import { getClientAndDoctorUrl, isDoctorUrl } from 'utils/url'
import { graphql, navigate } from 'gatsby'
import * as queryString from 'query-string'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { SearchResultList } from './component/search-result.component'
import { getSearchJsonLd } from '@root/src/components/legacy/mol.seo/build-search-json-ld'

export interface SearchPageQueryParams extends SearchQueryParam {
  /** selected tab index */
  index: string
}

interface SearchPageProps {}

const SearchPage: React.FunctionComponent<PageProps<SearchPageProps, Query>> = props => {
  const search: Partial<SearchPageQueryParams> = queryString.parse(props.location.search)

  React.useEffect(() => {
    if (!search.busca) {
      navigate(
        isDoctorUrl(props.location.pathname)
          ? pagesPaths.homeDoctor.path
          : pagesPaths.homeClient.path
      )
    }
  }, [search.busca])

  const staticInfoCosmicData = props.data.cosmicjsInformacoesEstaticas
  const seoData = staticInfoCosmicData.metadata.seo
  const title = `${staticInfoCosmicData.title}${
    search && search.busca ? ` para "${search.busca}"` : ''
  }`
  const siteUrl = props.data.site.siteMetadata.siteUrl

  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  const memoizedRobots = React.useMemo(
    () => (
      <Helmet>
        {/* // https://developers.google.com/search/reference/robots_meta_tag?hl=pt-br */}
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
    ),
    []
  )

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <GlobalSearchContext.Provider value={{ search: search.busca }}>
        <IndexLayout location={props.location}>
          <SEO
            socialMedia={{
              title,
              canonicalUrl: getCanonicalUrl(siteUrl, appPaths.search.path),
              image: seoData.imagem.url,
              imageAlt: seoData.imagemAlt,
              description: seoData.descricao
            }}
            jsonld={getSearchJsonLd({
              url: siteUrl,
              searchPath: appPaths.search.path.clientUrl,
              searchQueryParamName: 'busca'
            })}
          />
          {memoizedRobots}

          <Grid>
            <TitleWithBreadcrumbRow title={title} />

            <Row>
              <Col xs={12}>
                <SearchResultList
                  location={props.location}
                  staticInfoCosmicData={staticInfoCosmicData}
                />
              </Col>
            </Row>
          </Grid>
        </IndexLayout>
      </GlobalSearchContext.Provider>
    </ClientDoctorSelectContext.Provider>
  )
}

export default SearchPage

export const query = graphql`
  query Busca {
    cosmicjsInformacoesEstaticas(slug: { eq: "busca" }) {
      title
      metadata {
        buscaNaoEncontrouTitulo
        buscaNaoEncontrouTexto
        seo {
          titulo
          descricao
          imagem {
            url
          }
          imagemAlt
        }
      }
    }
    site {
      ...SiteUrl
    }
  }
`

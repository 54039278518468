import * as React from 'react'
import { HorizontalScrollStyled } from './horizontal-scroll.component.style'

export interface HorizontalScrollProps {
  noGutter?: boolean
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
  ref?: any
}

// This component is a wrapper that make your content horizontally scrollable
export const HorizontalScroll: React.FunctionComponent<HorizontalScrollProps> = React.forwardRef((props, ref) => {
  return (
    <HorizontalScrollStyled ref={ref} noGutter={props.noGutter} alignItems={props.alignItems}>
      {props.children}
    </HorizontalScrollStyled>
  )
})

HorizontalScroll.defaultProps = {
  alignItems: 'stretch'
}

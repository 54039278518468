import QueryString from 'query-string'
import { navigate } from 'gatsby'
import * as React from 'react'
import { getOffset, getPaginationQueryParam } from './pagination-query-param-getter'
import { PaginationRowLimitProps } from '../pagination-wrapper.component'
import { hasWindow } from 'utils/browser'

export interface PaginationContainerChildrenProps {
  pagination: {
    current: number
    offset: number
    onPageChange: (page: number) => void
  }
  limit: PaginationRowLimitProps
}
export interface PaginationContainerProps {
  children: (props: PaginationContainerChildrenProps) => JSX.Element
}

/**
 * Use this component WHEN you want to handle the pagination of your app using query-params
 * This componente read/update the URL according to pagination changes
 */
export class PaginationContainer extends React.Component<PaginationContainerProps, undefined> {
  constructor(props) {
    super(props)
  }

  render() {
    const params = getPaginationQueryParam()
    const childrenProps: PaginationContainerChildrenProps = {
      pagination: {
        current: params.page,
        offset: getOffset(params.page, params.limit),
        onPageChange: this.handlePageChange
      },
      limit: {
        current: params.limit,
        onLimitChange: this.handleLimitChange
      }
    }
    return this.props.children(childrenProps)
  }

  private handlePageChange = (page: number) => {
    const pathname = hasWindow() ? window.location.pathname : ''
    const query = getPaginationQueryParam()
    if (Number(query.page) !== page) {
      navigate(`${pathname}?${QueryString.stringify({ ...query, page })}`)
    }
  }

  private handleLimitChange = (limit: number) => {
    const pathname = hasWindow() ? window.location.pathname : ''
    const query = getPaginationQueryParam()
    if (Number(query.limit) !== limit) {
      /** UX decision: If the user changes the 'size', then they will be redirected to page 1 */
      query.page = 1

      navigate(`${pathname}?${QueryString.stringify({ ...query, limit })}`)
    }
  }
}

import * as queryString from 'query-string'
import { hasWindow } from 'utils/browser'

export enum PaginationRowLimit {
  one = 1,
  ten = 10,
  twenty = 20,
  fifty = 50,
  hundred = 100
}

/**
 * use this const to set an undefined pagination on your query-params
 * this is useful when you need to reset the pagination
 * ex: when the user changes 'order by', 'filter', 'search'
 */
export const PaginationResetQueryParams = {
  page: undefined
}

export interface PaginationQueryParam {
  limit: number
  page: number
}

/**
 * get pagination query params. If they don't exist, this helper tries to set
 * some default values
 * If you want to force some values, you can pass the config param
 */
export function getPaginationQueryParam(config?: Partial<PaginationQueryParam>) {
  const search = hasWindow() ? window.location.search : ''
  const queryParam: PaginationQueryParam = queryString.parse(search) as any
  const defaulConfig = getDefaulPaginationQueryParams()
  queryParam.limit = Number(queryParam.limit) || (config && config.limit) || defaulConfig.limit
  queryParam.page = Number(queryParam.page) || (config && config.page) || defaulConfig.page

  return queryParam
}

function getDefaulPaginationQueryParams() {
  return {
    limit: PaginationRowLimit.ten,
    page: 1
  } as PaginationQueryParam
}

export function getOffset(page: number, limit: number) {
  return (page - 1) * limit
}

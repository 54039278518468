import { ListNoBullets } from '@root/src/components/atm.list-no-bullets/ListNoBullets.component'
import SvgBuscaComErro from 'atomic/atm.svg-icon/ic-busca-com-erro'
import { FetchContainer } from '@root/src/components/legacy/obj.http-container/fetch-container.component'
import { getPaginationQueryParam } from '@root/src/components/legacy/obj.pagination-container/pagination-query-param-getter'
import { PaginationContainer } from '@root/src/components/legacy/obj.pagination-container/pagination.container'
import { PaginationRow } from '@root/src/components/legacy/pagination-wrapper.component'
import {
  mapToSearchResponse,
  SearchResponse
} from '@root/src/data/http/dto/global-elastic-search.response'
import { buildGlobalElasticSearchRequest } from '@root/src/data/http/request-builder/global-elastic-search.request'
import {
  ElasticSearchIndex
  // mapElasticSearchIndexToString
} from '@root/src/data/http/utils/elastic-search-index'
import { getPathForElasticSearchIndex } from '@root/src/utils/elastic-search-path'
import { isDoctorUrl } from 'utils/url'
import { Col, Row, Separator } from 'atomic'
import { Placeholder } from 'atomic/legacy/mol.placeholder'
import { TextShimmerBoxStyled } from 'atomic/legacy/mol.shimmer/shimmer.component.style'
import { LoadingState } from 'atomic/legacy/obj.loading-state'
import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'
import { SearchResultItem, SearchResultItemShimmer } from './search-result-item.component'

interface SearchResultSelectedTabDetailProps {
  search: string
  location: Location
  selectedIndex: ElasticSearchIndex[]
}

export const SearchResultSelectedTabDetail: React.FunctionComponent<SearchResultSelectedTabDetailProps> = props => {
  const sanitizeElasticIndex = (index: string) => {
    let text = index.replace(/(fleury|weinmann|felippe-mattoso|felippe_mattoso)-/, '')
    text = text.replace(/(fleury|weinmann|felippe_mattoso|felippe_mattoso)_/, '')

    text = text.replace(text.substring(text.length - 14), '')

    return text
  }
  const paginationQueryParams = getPaginationQueryParam()
  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              fleuryElasticSearchUrl
            }
          }
        }
      `}
      render={env => (
        <FetchContainer
          input={buildGlobalElasticSearchRequest(
            props.search,
            // props.selectedIndex,
            paginationQueryParams.page,
            paginationQueryParams.limit,
            sanitizeElasticIndex(props.selectedIndex[0])
          )}
          defaultBaseUrl={env.site.siteMetadata.fleuryElasticSearchUrl}
          mapper={mapToSearchResponse}
        >
          {childFetchState => {
            const searchResponse: SearchResponse = childFetchState.data
            const results = searchResponse && searchResponse.results
            const itemsCount = searchResponse && searchResponse.total.value

            return (
              <LoadingState {...childFetchState}>
                <LoadingState.Shimmer>
                  <SearchResultSelectedTabDetailShimmer />
                </LoadingState.Shimmer>

                <LoadingState.Error>
                  <Row>
                    <Col xs={12}>
                      <Placeholder
                        icon={<SvgBuscaComErro />}
                        title={'Erro na busca'}
                        description="Tente novamente mais tarde ou ligue para nossa central de atendimento."
                      />
                    </Col>
                  </Row>
                </LoadingState.Error>

                <PaginationContainer>
                  {paginationProps => {
                    const showPagination = paginationProps.limit.current < itemsCount
                    return (
                      <>
                        <Row center="xs" mb>
                          <Col xs={12}>
                            <ListNoBullets id="list-search-result-selected">
                              {results &&
                                results.map(item => (
                                  <>
                                    <SearchResultItem
                                      slug={item.slug}
                                      title={item.title}
                                      imgSrc={item.image}
                                      imgAlt={`imagem representando ${item.title}`}
                                      description={item.description}
                                      link={getPathForElasticSearchIndex(
                                        isDoctorUrl(props.location.pathname),
                                        item.slug,
                                        item.index
                                      )}
                                    />
                                  </>
                                ))}
                            </ListNoBullets>
                          </Col>
                        </Row>

                        {/* Dis-comment to see server response */}
                        {/* <pre>{JSON.stringify(searchResponse, null, 2)}</pre> */}

                        {showPagination && (
                          <>
                            <Separator />
                            <PaginationRow
                              pagination={{ ...paginationProps.pagination, total: itemsCount }}
                              limit={paginationProps.limit}
                            />
                          </>
                        )}
                      </>
                    )
                  }}
                </PaginationContainer>
              </LoadingState>
            )
          }}
        </FetchContainer>
      )}
    />
  )
}

const SearchResultSelectedTabDetailShimmer: React.FunctionComponent<any> = _props => {
  return (
    <>
      <Row>
        <Col>
          <Separator />
          <TextShimmerBoxStyled height="24px" width="300px" />
        </Col>
      </Row>
      <Row center="xs" mb>
        <Col xs={12}>
          <ListNoBullets id="list-search-result-selected-shimmer">
            {Array(4)
              .fill(1)
              .map((_val, index) => (
                <SearchResultItemShimmer key={index} />
              ))}
          </ListNoBullets>
        </Col>
      </Row>
    </>
  )
}

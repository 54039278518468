import sanitizeRegex from '@root/src/utils/sanitize-regex-elastic'
import { FetchDataSourceInput } from '../../fetch.datasource'
import { getIndexes } from './brand-indexes'
// import { getStorage, StorageType } from '../../storage/storage.provider'

// let GlobalElasticSearchAggregationURL = '/_search'
// if (process.env.GATSBY_COSMIC_BUCKET.toLocaleUpperCase() === 'FLEURY') {
//   GlobalElasticSearchAggregationURL = `/fleury*${GlobalElasticSearchAggregationURL}`
// } else if (process.env.GATSBY_COSMIC_BUCKET.toLocaleUpperCase() === 'WEINMANN') {
//   GlobalElasticSearchAggregationURL = `/weinmann*${GlobalElasticSearchAggregationURL}`
// } else if (process.env.GATSBY_COSMIC_BUCKET.toLocaleUpperCase() === 'FM') {
//   GlobalElasticSearchAggregationURL = `/felippe-mattoso*${GlobalElasticSearchAggregationURL}`
// }

export const buildGlobalElasticAggregationRequest = (search: string) => {
  // const sessionStorage = getStorage(StorageType.Session)

  // let token = sessionStorage.get('AccessToken')
  // if (token) {
  //   token = token.replace(/['"]+/g, '')
  // }
  const request: FetchDataSourceInput = {
    method: 'post',
    // url: GlobalElasticSearchAggregationURL,
    url: `${process.env.GATSBY_FLEURY_BASE_URL}/elasticsearch/digital/free-search`,
    data: buildGlobalElasticAggregationRequestData(search)
  }

  return request
}

/**
 *
 * aggregations: https://www.elastic.co/guide/en/elasticsearch/reference/6.7/search-aggregations.html
 * terms aggregations: https://www.elastic.co/guide/en/elasticsearch/reference/6.7/search-aggregations-bucket-terms-aggregation.html
 * max: https://www.elastic.co/guide/en/elasticsearch/reference/6.7/search-aggregations-metrics-max-aggregation.html
 *
 */
const buildGlobalElasticAggregationRequestData = (search: string) => {
  return {
    search: {
      query: {
        multi_match: {
          query: `${search?.replace(sanitizeRegex, '')}`,
          type: 'phrase_prefix'
        }
      },
      aggs: {
        byindex: {
          terms: {
            field: '_index'
          },
          aggs: {
            grades_stats: {
              max: {
                script: '_score'
              }
            }
          }
        }
      },
      size: 0
    },
    index: getIndexes()
  }
}

import { ButtonProps } from 'atomic/atm.button/button.component'
import { ButtonStyled } from 'atomic/atm.button/button.component.style'
import { Color, FontSize, FontWeight, Spacing, TransitionDuration } from 'atomic/legacy/obj.constants'
import styled from 'styled-components'
import { BodyStyle } from '../atm.typography'

export const PaginationStyled = styled.div`
  margin-top: ${Spacing.XSmall};
`

export const PaginationButtonStyled = styled.div`
  ${BodyStyle}
  color: ${Color.GrayXDark};
  font-size: ${FontSize.Small};
  font-family: ${props => props.theme.typography.primary};
  font-weight: ${FontWeight.SemiBold};
  cursor: pointer;
  display: inline-block;

  :first-of-type {
    margin-right: ${Spacing.Large};
  }
  :last-of-type {
    margin-left: ${Spacing.Large};
  }
`

interface PageStyledProps extends ButtonProps {
  active: boolean
}

const size = '40px'
export const PageStyled = styled(ButtonStyled)<PageStyledProps>`
  border-width: 0;
  background-color: transparent;
  border-radius: 50%;
  color: ${(props) => (props.active ? props.theme.color.primary : Color.GrayXDark)};

  display: inline-block;
  transition: all ${TransitionDuration};
  min-height: ${size};
  height: ${size};
  width: ${size};
  padding: 0;
  margin: 0 ${Spacing.XSmall};

  :hover {
    background: ${Color.GrayXLight};
  }
`

export const PageDisabledStyled = styled.label`
  padding: ${Spacing.Medium};
  border-width: 0;
`

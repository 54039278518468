import { SearchPageQueryParams } from '@app/modules/search/search'
import { Body, Col, H2, Row, Separator } from 'atomic'
import { Segmented } from 'atomic/legacy/atm.segmented'
import { Placeholder } from 'atomic/legacy/mol.placeholder'
import { TextShimmerBoxStyled } from 'atomic/legacy/mol.shimmer/shimmer.component.style'
import { LoadingState } from 'atomic/legacy/obj.loading-state'
import { HorizontalScroll } from 'atomic/obj.horizontal-scroll'
import SvgBuscaComErro from 'atomic/atm.svg-icon/ic-busca-com-erro'
import SvgBuscaSemResultado from 'atomic/atm.svg-icon/ic-busca-sem-resultado'
import { FetchContainer } from '@root/src/components/legacy/obj.http-container/fetch-container.component'
import { CosmicjsInformacoesEstaticas } from '@root/src/data/graphql/graphql-types'
import {
  mapToSearchAggregationResponse,
  SearchAggregationResponse
} from '@root/src/data/http/dto/global-elastic-search-aggregation.response'
import { buildGlobalElasticAggregationRequest } from '@root/src/data/http/request-builder/global-elastic-search-aggregation.request'
import { mapElasticSearchIndexToString } from '@root/src/data/http/utils/elastic-search-index'
import { isDoctorUrl } from 'utils/url'
import { graphql, navigate, StaticQuery } from 'gatsby'
import * as queryString from 'query-string'
import * as React from 'react'
import { isNullOrUndefined } from 'util'
import { SearchResultSelectedTabDetail } from './search-result-selected-tab-detail.component'

interface SearchResultProps {
  location: Location
  staticInfoCosmicData: CosmicjsInformacoesEstaticas
}

export const SearchResultList: React.FunctionComponent<SearchResultProps> = props => {
  const doctor = isDoctorUrl(props.location.pathname)
  const search: Partial<SearchPageQueryParams> = queryString.parse(props.location.search)

  const onSegmentedControlChange = val => {
    const nextQueryParam: Partial<SearchPageQueryParams> = { ...search, index: val, page: 1 }
    navigate(`${props.location.pathname}/?${queryString.stringify(nextQueryParam)}`)
  }
  const staticInfo = React.useMemo(
    () => (
      <>
        <H2 cell>{props.staticInfoCosmicData.metadata.buscaNaoEncontrouTitulo}</H2>
        <div
          dangerouslySetInnerHTML={{
            __html: props.staticInfoCosmicData.metadata.buscaNaoEncontrouTexto
          }}
        />
        <Separator />
      </>
    ),
    []
  )

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              fleuryElasticSearchUrl
            }
          }
        }
      `}
      render={env => (
        <FetchContainer
          input={buildGlobalElasticAggregationRequest(search.busca)}
          mapper={mapToSearchAggregationResponse}
          defaultBaseUrl={env.site.siteMetadata.fleuryElasticSearchUrl}
        >
          {fetchState => {
            const aggregations: SearchAggregationResponse = fetchState.data
            const categories =
              aggregations &&
              aggregations.categories.filter(category =>
                mapElasticSearchIndexToString(category.index, doctor)
              )
            const resultCount =
              categories && categories.reduce((acc, curr) => acc + curr.quantity, 0)
            const hasSomeData = categories && categories.length > 0
            const selectedIndex = Number(search.index) || 0
            if (fetchState.error) {
              console.error('SearchPage: state.error', fetchState.error)
            }
            return (
              <LoadingState
                loading={fetchState.loading}
                error={fetchState.error}
                data={hasSomeData}
              >
                <LoadingState.Shimmer>
                  <TextShimmerBoxStyled height="50px" />
                  <Separator />
                </LoadingState.Shimmer>

                <LoadingState.Error>
                  <Row between="xs">
                    <Col xs={12} md={8}>
                      <Placeholder
                        icon={<SvgBuscaComErro />}
                        title={'Erro na busca'}
                        description="Tente novamente mais tarde ou ligue para nossa central de atendimento."
                      />
                    </Col>
                    <Col md={3} mdOffset={1}>
                      {staticInfo}
                    </Col>
                  </Row>
                </LoadingState.Error>

                <LoadingState.NoData>
                  <Row>
                    <Col xs={12} md={8}>
                      <Placeholder
                        icon={<SvgBuscaSemResultado />}
                        title={'Nenhum resultado encontrado'}
                        description="Tente buscar outro termo ou ligue para nossa central de atendimento."
                      />
                    </Col>
                    <Col md={3} mdOffset={1}>
                      {staticInfo}
                    </Col>
                  </Row>
                </LoadingState.NoData>

                <Row noGutter>
                  <Col xs={12}>
                    <HorizontalScroll>
                      <Segmented
                        key={selectedIndex}
                        onChange={onSegmentedControlChange}
                        defaultIndex={selectedIndex}
                      >
                        {categories &&
                          categories.map(category => {
                            const categoryName = mapElasticSearchIndexToString(
                              category.index,
                              doctor
                            )
                            return (
                              <Segmented.Item
                                id={`segmented-item-${category.index}`}
                                key={category.index}
                              >
                                {categoryName} ({category.quantity})
                              </Segmented.Item>
                            )
                          })}
                      </Segmented>
                    </HorizontalScroll>
                  </Col>
                </Row>

                <Separator />
                <Row>
                  <Col xs={12} sm={12} md={7} lg={7}>
                    {resultCount && (
                      <Body>
                        {resultCount > 1
                          ? `${resultCount} resultados encontrados.`
                          : `1 resultado encontrado.`}
                      </Body>
                    )}
                    {categories && categories.length && !isNullOrUndefined(selectedIndex) && (
                      <SearchResultSelectedTabDetail
                        location={props.location}
                        search={search.busca}
                        selectedIndex={[categories[selectedIndex].index]}
                      />
                    )}
                  </Col>

                  <Col xs={12} md={4} mdOffset={1} lg={4}>
                    {staticInfo}
                  </Col>
                </Row>
              </LoadingState>
            )
          }}
        </FetchContainer>
      )}
    />
  )
}

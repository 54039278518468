import { BodySecondary, Col, Row, Separator } from 'atomic'
import { Pagination } from 'atomic/legacy/atm.pagination'
import { SelectField } from 'atomic/legacy/atm.select'
import * as React from 'react'
import { PaginationRowLimit } from './obj.pagination-container/pagination-query-param-getter'

const PaginationWindow = 2

export interface PaginationRowPaginationProps {
  current: number
  /** total number of items */
  total: number
  onPageChange: (page: number) => void
}
export interface PaginationRowLimitProps {
  current: PaginationRowLimit
  onLimitChange: (limit: PaginationRowLimit) => void
}
export interface PaginationRowProps {
  pagination: PaginationRowPaginationProps
  limit: PaginationRowLimitProps
  children?: any
}

export class PaginationRow extends React.Component<PaginationRowProps, undefined> {
  render() {
    return (
      <Row>
        <Col xs={12} sm={3} md={2} lg={4}>
          <Row middle="xs" start="xs">
            <Col xs={5} sm={7} md={12} lg={5}>
              <SelectField
                value={this.props.limit.current}
                onValueChange={this.props.limit.onLimitChange}
              >
                {Object.values(PaginationRowLimit)
                  .filter(item => typeof item !== 'string')
                  .map(item => (
                    <option value={item} aria-selected={false} key={item}>
                      {item}
                    </option>
                  ))}
              </SelectField>
            </Col>
            <Col xs={7} sm={5} md={12} lg={7}>
              <BodySecondary>itens por página</BodySecondary>
            </Col>
          </Row>
          <Separator />
        </Col>
        <Col xs={12} sm={9} md={10} lg={8}>
          <Pagination
            current={this.props.pagination.current}
            total={Math.ceil(this.props.pagination.total / this.props.limit.current)}
            window={PaginationWindow}
            onPageChange={this.props.pagination.onPageChange}
          />
          <Separator />
        </Col>
      </Row>
    )
  }
}

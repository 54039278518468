import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { Body, HDisplay } from 'atomic/legacy/atm.typography'
import { Separator } from 'atomic/legacy/obj.box'
import { Col, Row } from 'atomic/legacy/obj.grid'
import * as React from 'react'
import { PlaceholderColStyled } from './placeholder.component.style'

export interface PlaceholderProps {
  icon: JSX.Element
  title: string
  description?: string
  children?: any
}

export const Placeholder: React.SFC<PlaceholderProps> = (props: PlaceholderProps) => {
  return (
    <>
      <Row reverse mt>
        <PlaceholderColStyled xs={12} mdOffset={1} md={6}>
          {props.icon}
          <Separator />
        </PlaceholderColStyled>
        <Col xs={12} md={5}>
          <HDisplay>{props.title}</HDisplay>
          {props.description && (
            <>
              <Separator />
              <Body>{props.description}</Body>
            </>
          )}

          <Separator />
        </Col>
      </Row>
      <LargeSeparatorRow />
    </>
  )
}

// tslint:disable

import { FaIcon } from 'atomic/legacy/atm.fa-icon'
import { Color, Spacing } from 'atomic/legacy/obj.constants'
import { fieldBorderCss, fieldCss } from 'atomic/legacy/obj.form/field.component.styled'
import styled from 'styled-components'
import { SelectProps } from './select.component'


export const SelectWrapperStyled = styled.div`
  ${fieldBorderCss}
  position: relative;
  padding: 0 ${Spacing.Small};
`

export const SelectStyled = styled.select<SelectProps>`
  appearance: none;
  border: 0;
  min-width: 100px;
  outline: none;
  ${fieldCss}
`

export const SelectIconStyled = styled(FaIcon.ChevronDown)`
  position: absolute;
  top: 12px;
  right: ${Spacing.Large};
  color: ${Color.GrayDark};

  pointer-events: none;
`

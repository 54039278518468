import { bodySecondaryFontSize, bodySecondaryLineHeight, Border, H3, Spacing } from 'atomic'
import { textMaxLines } from 'atomic/legacy/obj.mixin'
import styled from 'styled-components'

export const SearchResultItemTextStyled = styled(H3)`
  margin-bottom: ${Spacing.Small};
`

export const SearchResultItemStyled = styled.li`
  padding: ${Spacing.Large} 0;
  border-bottom: ${Border.Width} solid ${Border.Color};

  :hover {
    ${SearchResultItemTextStyled} {
      text-decoration: underline;
    }
  }
`

export const SearchResultItemBodyWrapperStyled = styled.div`
  ${textMaxLines(3, bodySecondaryLineHeight * parseInt(bodySecondaryFontSize, 0))};
  margin-bottom: ${Spacing.Small};

  :before {
    content: '';
  }
  :after {
    content: '…';
  }
`

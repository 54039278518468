import { BodySecondary, Button, Hbox } from 'atomic'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image/lazy-load-image.component'
import { TextShimmerBoxStyled } from 'atomic/legacy/mol.shimmer/shimmer.component.style'
import {
  SearchResultItemBodyWrapperStyled,
  SearchResultItemStyled,
  SearchResultItemTextStyled
} from '@root/src/modules/search/component/search-result-item.style'
import { isExternalUrl } from 'utils/url'
import { Link } from 'gatsby'
import * as React from 'react'

interface SearchResultItemProps {
  title: string
  description: string
  imgSrc: string
  imgAlt: string
  link: string
  linkText?: string
  slug: string
}

export const SearchResultItem: React.FunctionComponent<SearchResultItemProps> = ({
  slug,
  ...props
}) => {
  const content = (
    <SearchResultItemStyled>
      <Hbox>
        {props.imgSrc && (
          <>
            <Hbox.Item wrap>
              <LazyLoadImage width={83} height={83} alt={props.imgAlt} src={props.imgSrc} round />
            </Hbox.Item>

            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Separator />
          </>
        )}
        <Hbox.Item>
          <SearchResultItemTextStyled cell>{props.title}</SearchResultItemTextStyled>
          {props.description && (
            <SearchResultItemBodyWrapperStyled>
              <BodySecondary>
                <div dangerouslySetInnerHTML={{ __html: props.description }} />
              </BodySecondary>
            </SearchResultItemBodyWrapperStyled>
          )}
          <div>
            <Button id={`button-search-result-${slug}`} kind="link" to={props.link}>
              {props.linkText}
            </Button>
          </div>
        </Hbox.Item>
      </Hbox>
    </SearchResultItemStyled>
  )
  return isExternalUrl(props.link || '') ? (
    <a target="_blank" href={props.link}>
      {content}
    </a>
  ) : (
    <Link to={props.link}>{content}</Link>
  )
}

export const SearchResultItemShimmer: React.FunctionComponent<any> = _props => {
  return (
    <SearchResultItemStyled>
      <TextShimmerBoxStyled height="24px" width="120px" />
      <TextShimmerBoxStyled height="16px" width="200px" />
      <TextShimmerBoxStyled height="16px" width="70px" />
    </SearchResultItemStyled>
  )
}

SearchResultItem.defaultProps = {
  linkText: 'Ver mais'
}

// tslint:disable
import * as React from 'react'
import { PageDisabledStyled, PageStyled, PaginationButtonStyled, PaginationStyled } from './pagination.component.style'

export interface PageItemProps {
  kind?: string
  active: boolean
  first: boolean
  last: boolean
  children: any
  onClick: any
}

const SEPARATOR = '...'

const PaginationItem = (props: PageItemProps) => {
  const { active } = props

  const onClick = () => {
    props.onClick(parseInt(props.children, 0))
  }

  if (props.children === SEPARATOR) {
    return <PageDisabledStyled>{props.children}</PageDisabledStyled>
  }
  return (
    <PageStyled kind="neutral" onClick={onClick} active={active}>
      {props.children}
    </PageStyled>
  )
}

export interface PaginationProps {
  current: number
  total: number
  window: number
  onPageChange: (page: number) => void
}
export const Pagination = (props: PaginationProps) => {
  const pagesArray: string[] = []
  const halfWindow = Math.round(props.window / 2)

  const startPage = props.current - halfWindow > 0 ? props.current - halfWindow : 1
  const endPage = props.current + halfWindow > props.total ? props.total : props.current + halfWindow

  if (startPage === endPage) {
    return null
  }

  if (startPage > 1) {
    pagesArray.push('1')
  }
  if (startPage + 1 > 2) {
    pagesArray.push(SEPARATOR)
  }

  for (let page = startPage; page <= endPage && page <= props.total; page++) {
    pagesArray.push(page.toString())
  }
  if (props.total - 1 > endPage) {
    pagesArray.push(SEPARATOR)
  }
  if (endPage < props.total) {
    pagesArray.push(props.total.toString())
  }

  const handlePreviousClick = React.useCallback(() => {
    props.onPageChange(props.current - 1)
  }, [props.current])
  const handleNextClick = React.useCallback(() => {
    props.onPageChange(props.current + 1)
  }, [props.current])

  return (
    <PaginationStyled>
      {props.current !== startPage && <PaginationButtonStyled onClick={handlePreviousClick}>Anterior</PaginationButtonStyled>}
      {pagesArray.map((val: string, index: number) => (
        <PaginationItem
          first={index === 0 || pagesArray[index - 1] === SEPARATOR}
          last={val === props.total.toString() || pagesArray[index + 1] === SEPARATOR}
          key={'name' + index}
          active={val === props.current.toString()}
          onClick={props.onPageChange}
        >
          {val}
        </PaginationItem>
      ))}
      {props.current !== endPage && <PaginationButtonStyled onClick={handleNextClick}>Próximo</PaginationButtonStyled>}
    </PaginationStyled>
  )
}

import { ElasticSearchIndex } from '../utils/elastic-search-index'
import {
  CosmicjsArtigosCientificos,
  CosmicjsCorpoClinico,
  CosmicjsNoticias,
  CosmicjsReceptivosDeCampanhas,
  CosmicjsRevistasFleury,
  CosmicjsRoteirosDiagnosticos,
  Unidades,
  Exames
} from '../../graphql/graphql-types'

export interface SearchResponse {
  results: {
    title: string
    description: string
    image: string
    slug: string
    index: ElasticSearchIndex
    // TODO: SEARCH - remove when possible
    data: any
  }[]
  total: number
}

export const mapToSearchResponse = (input: GlobalElasticSearchResponse) => {
  return {
    results: input.data.hits.hits.map(hit => {
      const image = hit._source && hit._source.thumbnail && hit._source.thumbnail.imgixUrl

      // const about = hit._source && hit._source.sobre

      const descriptionEntry =
        hit.highlight &&
        Object.entries(hit.highlight).find(
          entry =>
            !entry[0].includes('slug') &&
            !entry[0].includes('url') &&
            !entry[0].includes('searchableTerms') &&
            !entry[0].includes('title')
        )

      const titleEntry = hit.highlight
        ? Object.entries(hit.highlight).find(entry => entry[0].includes('title'))
        : hit._source.title

      // const description = `${about ? `${about.slice(0, 100)}… ` : ''}${
      //   descriptionEntry || titleEntry
      //     ? (descriptionEntry && descriptionEntry[1] && descriptionEntry[1][0]) ||
      //       (titleEntry && titleEntry[1] && titleEntry[1][0])
      //     : ''
      // }`

      const description = `
      ${
        descriptionEntry || titleEntry
          ? (descriptionEntry && descriptionEntry[1] && descriptionEntry[1][0]) ||
            (titleEntry && titleEntry[1] && titleEntry[1][0])
          : ''
      }
      `

      const slug =
        // roteiros diagnósticos
        (hit._source && (hit._source as any).scriptFile && (hit._source as any).scriptFile.url) ||
        // outros
        hit._source.slug

      return {
        image,
        slug,
        description,
        title: hit._source.name ? hit._source.name : hit._source.title,
        index: hit._index,
        data: hit
      }
    }),
    total: input.data.hits.total
  } as SearchResponse
}

////////////////////////////////////////////////////////////////////////////////////////////////////
/** generated using: http://json2ts.com/ */
interface GlobalElasticSearchResponse {
  data: Data
}

interface Shards {
  total: number
  successful: number
  skipped: number
  failed: number
}

interface Highlight {
  [key: string]: string
}

type Source = CosmicjsRevistasFleury &
  CosmicjsArtigosCientificos &
  CosmicjsRoteirosDiagnosticos &
  CosmicjsReceptivosDeCampanhas &
  CosmicjsNoticias &
  CosmicjsCorpoClinico &
  Exames &
  Unidades

interface Hit {
  _index: string
  _type: string
  _id: string
  _score: number
  _source: Source
  highlight: Highlight
}

interface Hits {
  total: number
  max_score: number
  hits: Hit[]
}

interface Data {
  took: number
  timed_out: boolean
  _shards: Shards
  hits: Hits
}

import * as React from 'react'
import { FormFieldContext, FormFieldContextState } from 'atomic/legacy/obj.form'
import { SelectIconStyled, SelectStyled, SelectWrapperStyled } from './select.component.style'

export interface SelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  disabled?: boolean
  invalid?: boolean
  onValueChange?: (value: any) => void
  initialValue?: any
}
interface SelectState {
  value?: any
}
// tslint:disable-next-line:class-name
export class SelectField extends React.Component<SelectProps, SelectState> {
  private formFieldConsumer: FormFieldContextState

  constructor(props) {
    super(props)
    this.state = { value: this.props.initialValue || this.props.value }
  }
  componentDidMount() {
    if (this.isControlled() && this.props.initialValue !== undefined) {
      throw new Error('Use either the initialValue prop, or the value prop, but not both')
    }

    if (this.formFieldConsumer) {
      if (this.isControlled() || this.props.initialValue !== undefined) {
        if (this.formFieldConsumer.value) {
          throw new Error('Please, use either value props in <Select> or <Form.Field> component.')
        }

        this.formFieldConsumer.onValueChange(this.state.value, null)
      }
    }
  }

  componentDidUpdate(prevProps: SelectProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      this.setState({ value: this.props.value })

      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.value)
      }

      if (this.formFieldConsumer) {
        this.formFieldConsumer.onValueChange(this.props.value, null)
      }
    }
  }

  render() {
    const { type, onChange, invalid, onValueChange, value, ...other } = this.props
    return (
      <FormFieldContext.Consumer>
        {(formFieldConsumer: FormFieldContextState) => {
          this.formFieldConsumer = formFieldConsumer
          const val = formFieldConsumer && formFieldConsumer.value ? formFieldConsumer.value : this.state.value
          return (
            <SelectWrapperStyled invalid={this.props.invalid}>
              <SelectStyled type={type} onChange={this.handleSelectChange} value={val} {...other} />
              <SelectIconStyled />
            </SelectWrapperStyled>
          )
        }}
      </FormFieldContext.Consumer>
    )
  }

  private handleSelectChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const value: string = event.currentTarget.value

    if (this.props.onValueChange) {
      this.props.onValueChange(value)
    }

    if (this.isControlled()) {
      return
    }

    if (this.formFieldConsumer) {
      this.formFieldConsumer.onValueChange(value, { touched: true })
    } else {
      this.setState({ value })
    }
  }

  private isControlled = () => this.props.value !== undefined
}
